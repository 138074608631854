<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getUsersList"
  >
    <template #title> Referred Users </template>
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <custom-date-picker
        @getSelectedDate="setCreateDateFilter"
        inputLabel="Signup Date Filter"
        inputPlaceholder="2023-12-06"
        inputId="CreatedateFilter"
      ></custom-date-picker>
      <search-input
        @enter="getUsersList"
        v-model="usersListFilters.inviteCode"
        inputId="inviteCodeFilter"
        inputLabel="Referral Code"
        inputPlaceholder="2mvEl6hTf"
      ></search-input>
      <search-input
        @enter="getUsersList"
        v-model="usersListFilters.fullName"
        inputId="fullNameFilter"
        inputLabel="User Full Name"
        inputPlaceholder="s y"
      ></search-input>
      <search-input
        @enter="getUsersList"
        v-model="usersListFilters.sumTrade"
        inputId="sumTradeFilter"
        inputLabel="Sum amount of trade"
        inputPlaceholder="200"
      ></search-input>
      <search-input
        @enter="getUsersList"
        v-model="usersListFilters.userName"
        inputId="userNameFilter"
        inputLabel="Username"
        inputPlaceholder="farham"
      ></search-input>
    </template>
    <custom-table
      :pageNumber="usersListFilters.pageNumber"
      :count="usersListFilters.count"
      :totalCount="totalCount"
      :tableItems="usersList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
    </custom-table>
  </page-wrapper>
</template>

<script>
export default {
  title: "Referred Users List",
  mounted() {
    this.getUsersList();
  },
  data() {
    return {
      isLoading: false,
      usersListFilters: {
        pageNumber: 1,
        count: 10,
        userInviteLinkId: null,
        id: null,
        createDate: null,
        fullName: null,
        sumTrade: null,
        userName: null,
        justAvaliable: null,
        justAvaliableForCommission: null,
        inviteCode: null,
      },
      totalCount: null,
      usersList: null,
      myTableColumns: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "fullName",
          label: "User Fullname",
          formatter: (value) => value.trim() || "-",
        },
        {
          key: "userName",
          label: "UserName",
        },
        {
          key: "sumTrade",
          label: "Sum Amount Of Trade",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "commission",
          label: "Comission",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "releaseCommission",
          label: "Comission Available For Cashout",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "releaseCommissionProgressing",
          label: "Amount Of Comission In Cashout Progress",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "invidePrice",
          label: "Invited Users Gift",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "avaliableForReleaseInvidePrice",
          label: "Amount Of Invited Users Gift Available For Cashout",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "releaseInvidePrice",
          label: "Amount Of Users Gift Cashed Out",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "releaseInvidePriceProgressing",
          label: "Amount Of Invited Users Gift In Cashout Progress",
          formatter: (value) => `${parseFloat(value)} $`,
        },
      ],
    };
  },
  methods: {
    setCreateDateFilter(date) {
      this.usersListFilters.createDate = date;
    },
    changePage(pageNumber) {
      this.usersListFilters.pageNumber = pageNumber;
      this.getUsersList();
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns
        .post(`UserInviteLink/GetUserListForAdminExportExel`, null, {
          responseType: "blob",
          Authorization: userToken,
        })
        .then((resp) => {
          _this.overlay = false;
          saveAs(resp.data, "ReferredUsersList.xlsx");
        })
        .catch((error) => {
          _this.overlay = false;
          console.log(error);
        });
    },
    async getUsersList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getReferredUsersListForAdmin = new GetReferredUsersListForAdmin(
          _this
        );
        getReferredUsersListForAdmin.setRequestParamDataObj(
          _this.usersListFilters
        );
        await getReferredUsersListForAdmin.fetch((res) => {
          _this.totalCount = res.total;
          _this.usersList = res.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    PageWrapper,
    CustomDatePicker,
    SearchInput,
    CustomTable,
    BButton,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomDatePicker from "@/views/components/Utilities/CustomDatePicker.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import axiosIns from "@/libs/axios";
import { GetReferredUsersListForAdmin } from "@/libs/Api/UserInviteLink";
import { saveAs } from "file-saver";
import { getUserData } from "@/auth/utils";
import { BButton } from "bootstrap-vue";
</script>
